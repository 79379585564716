<template>
  <div>
    <custom-breadcrumb
      :pageTitle="$t('models.school')"
      :breadcrumb="[
        {
          text: $t('Schools'),
          to: 'schools'
        },
        {
          text: $t('Detail'),
          active: true,
        },
      ]"
    ></custom-breadcrumb>
    <template v-if="recordData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <info-card 
            :record-data="recordData"
            :special_needs="special_needs"
            @refetch-data="getData(recordData.id)"
          />
        
        </b-col>
        <b-col
          v-if="recordData.user_admin_id"
          cols="12"
          lg="6"
        >
          <b-row>
            <!-- TEACHERS CARD -->
            <b-col
              xl="6"
              md="6"
              sm="3"
            >
            <b-link
              v-if="recordData"
              :to="{ name: 'school-teachers-list', params: { id: recordData.id, name: recordData.name } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <statistic-card-vertical
                icon="UserIcon"
                :statistic="teachers_amount"
                :statistic-title="$t('Teachers')"
                color="info"
              />
              </b-link>
            </b-col>

            <!-- STUDENTS CARD -->
            <b-col
              xl="6"
              md="6"
              sm="3"
            >
              <b-link
                v-if="recordData"
                :to="{ name: 'school-students-list', 
                params: { id: recordData.id, name: recordData.name } }"
                class="font-weight-bold d-block text-nowrap"
              >
                <statistic-card-vertical
                  color="warning"
                  icon="HeartIcon"
                  :statistic="students_amount"
                  :statistic-title="$t('models.students')"
                />
              </b-link>
            </b-col>

            <!-- GROUPS CARD -->
            <b-col
              xl="6"
              md="6"
              sm="3"
            >
            <b-link
                v-if="recordData"
                :to="{ name: 'school-groups-list', 
                params: { id: recordData.id, name: recordData.name } }"
                class="font-weight-bold d-block text-nowrap"
              >
              <statistic-card-vertical
                color="danger"
                icon="UsersIcon"
                :statistic="groups_amount"
                :statistic-title="$t('Groups')"
              />
              </b-link>
            </b-col>
            
            <!-- DREAMERS CARD -->
            <b-col
              xl="6"
              md="6"
              sm="3"
            >
            <b-link
                v-if="recordData"
                :to="{ name: 'school-dreamers-list', 
                params: { id: recordData.id, name: recordData.name } }"
                class="font-weight-bold d-block text-nowrap"
              >
              <statistic-card-vertical
                color="primary"
                icon="SmileIcon"
                :statistic="dreamers_amount"
                :statistic-title="$t('Dreamers')"
              />
              </b-link>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-else>
          <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Attention!
            </h4>
            <div class="alert-body">
              <span>School doesn't have an admin licence. Edit it to create teachers, students, groups and dreamers.</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>

    </template>
    
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      v-else
      :show="recordData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching school data
      </h4>
      <div class="alert-body">
        No school found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'schools'}"
        >
          School List
        </b-link>
        for other schools.
      </div>
    </b-alert>

  
  </div>
</template>

<script>
import { fetchSchool } from '@/api/routes'

import router from '@/router'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

import InfoCard from './view/InfoCard.vue'
import {
  BRow, BCol, BAlert, BLink,
  BCard,
  BCardTitle,
  BCardSubTitle,
} from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'

export default {
  name:"LicencesView",
  components:{
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardActions,

    InfoCard,
    BCardActions,
    StatisticCardVertical,
    StatisticCardHorizontal,
    CustomBreadcrumb,
  },
  data(){
    return {
      id: null,
      recordData: null,
      special_needs: [],
      teachers_amount:'',
      students_amount:'',
      groups_amount:'',
      dreamers_amount:'',
    }
  },
  mounted(){
    let id = router.currentRoute.params.id;
    this.id = id;
    this.getData(id)
  },
  methods: {
    async getData(id){
        let data = {
          'id': id
        }
        await this.$http.post(fetchSchool, data).then( response =>{
            this.recordData = response.data.school;
            this.special_needs = response.data.special_needs;
            this.teachers_amount = response.data.teachers_amount;
            this.students_amount = response.data.students_amount;
            this.groups_amount = response.data.groups_amount;
            this.dreamers_amount = response.data.dreamers_amount;
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
      
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }

}
</script>

<style>

</style>